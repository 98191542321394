// const { $LRUCache } = useNuxtApp();
import { LRUCache } from "lru-cache";

const cache = new LRUCache({
    max: 10, //缓存队列长度
    //   maxSize: 5000,
    ttl: 1000 * 60, //缓存时长
});

//需要缓存的路由
let cacheList = ['/test/test2', '/test/test1']

// 页面缓存中间件
export default defineNuxtRouteMiddleware(async (to, from) => {
    // console.log('目标路由', to);
    if (cacheList.includes(to.fullPath)) {
        if (!cache.has("dic")) {
            let res = await getDicList({
                TypeCodes: "S001,S002,S003",
                IncludeDescendant: false,
            });
            cache.set("dic", res.data); //存入缓存

            console.log('dic', cache.get('dic'));

        } else {
            console.log('dic存在');

        }
    }
    // console.log('当前路由', from);
    // console.log('cache', $LRUCache);
})
